<template>
    <button class="winex-btn icon-btn" :class="classList" @click="onClick" :style="customAppendStyle">{{content}}</button>
</template>

<script>
    export default {
        name: "IconButton",
        props: {
            content: {
                default:"",
                type: String
            },
            classList: {
                default: "",
                type:String
            },
            icon: {
                default:"",
                type: String,
                required: true
            },
            appendStyle: {
                type: Object
            },
            onClick: {
                default: () => {},
                type: Function
            }
        },
        data() {
            return {
                customAppendStyle:this.appendStyle
            }
        },
        created() {
            this.customAppendStyle = {
                backgroundImage:`url(${this.icon})`
            }
        }
    }
</script>

<style scoped>

</style>
