<template>
    <div>
        <div id="component-list-wrap">
            <div class="component-list-item">
                <h4 class="component-list-title">버튼</h4>
                <h5 class="component-list-sub-title">기본 버튼</h5>
                <div class="component-item-wrap">
                    <DefaultButton
                    :content="'클릭'"
                    :onClick="openAlert"></DefaultButton>
                </div>

                <h5 class="component-list-sub-title">버튼색상</h5>
                <div class="component-item-wrap">
                    <DefaultButton
                            :content="'버튼'"
                            :class-list="'primary-btn'"></DefaultButton>
                    <DefaultButton
                            :content="'버튼'"
                            :class-list="'secondary-btn'"></DefaultButton>
                    <DefaultButton
                            :content="'버튼'"
                            :class-list="'light-btn'"></DefaultButton>
                </div>

                <h5 class="component-list-sub-title">아웃라인 버튼</h5>
                <div class="component-item-wrap">
                    <DefaultButton
                            :content="'버튼'"
                            :class-list="'outline-white-primary-btn'"></DefaultButton>
                    <DefaultButton
                            :content="'버튼'"
                            :class-list="'outline-bg0-primary-btn'"></DefaultButton>
                    <DefaultButton
                            :content="'버튼'"
                            :class-list="'outline-white-lld-btn'">light</DefaultButton>
                    <DefaultButton
                            :content="'버튼'"
                            :class-list="'outline-ll-lld-btn'">light</DefaultButton>
                </div>

                <h5 class="component-list-sub-title">버튼사이즈 가로(width 160,120,100)</h5>
                <div class="component-item-wrap">
                    <DefaultButton
                            :content="'w160(미지정)'"
                            :class-list="'primary-btn'"></DefaultButton>
                    <DefaultButton
                            :content="'w120'"
                            :class-list="'primary-btn w120'"></DefaultButton>
                    <DefaultButton
                            :content="'w100'"
                            :class-list="'primary-btn w100'"></DefaultButton>
                </div>

                <h5 class="component-list-sub-title">버튼사이즈 세로(height 52,48,40)</h5>
                <div class="component-item-wrap">
                    <DefaultButton
                            :content="'h52(미지정)'"
                            :class-list="'primary-btn'"></DefaultButton>
                    <DefaultButton
                            :content="'h48'"
                            :class-list="'primary-btn h48'"></DefaultButton>
                    <DefaultButton
                            :content="'h40'"
                            :class-list="'primary-btn h40'"></DefaultButton>
                </div>

                <h5 class="component-list-sub-title">버튼사이즈 혼합예</h5>
                <div class="component-item-wrap">
                    <DefaultButton
                            :content="'w100 h40'"
                            :class-list="'primary-btn w100 h40'"></DefaultButton>
                </div>
                <h5 class="component-list-sub-title">아이콘 버튼</h5>
                <div class="component-item-wrap">
                    <IconButton
                    :content="'버튼'"
                    :class-list="'primary-btn outline-white-lld-btn w100'"
                    :icon="require('@/assets/images/icon/Vector 375.png')"
                    ></IconButton>
                </div>
            </div>

            <div class="component-list-item">
                <h4 class="component-list-title">태그</h4>
                <div class="component-item-wrap">
                    <div class="winex-tag primary-tag">PRIMARY TAG</div>
                    <div class="winex-tag dark-tag">DARK TAG</div>
                    <div class="winex-tag quaternary-tag">QUATERNARY TAG</div>
                </div>
            </div>

            <div class="component-list-item">
                <h4 class="component-list-title">on/off input</h4>
                <h5 class="component-list-sub-title">라디오</h5>
                <div class="component-item-wrap">
                    <label class="winex-radio"><input type="radio" name="radio-test" value="라디오1" v-model="radioSample"><span>라디오1</span></label>
                    <label class="winex-radio"><input type="radio" name="radio-test" value="라디오2" v-model="radioSample"><span>라디오2</span></label>
                    <label class="winex-radio"><input type="radio" name="radio-test" value="라디오3" v-model="radioSample"><span>라디오3</span></label>
                </div>
                <div style="margin-top: -20px;">Selected: {{radioSample}}</div>

                <h5 class="component-list-sub-title">체크박스</h5>
                <div class="component-item-wrap">
                    <label class="winex-checkbox"><input type="checkbox" name="radio-test" value="체크박스1" v-model="checkboxSample"><span>체크박스1</span></label>
                    <label class="winex-checkbox"><input type="checkbox" name="radio-test" value="체크박스2" v-model="checkboxSample"><span>체크박스2</span></label>
                    <label class="winex-checkbox"><input type="checkbox" name="radio-test" value="체크박스3" v-model="checkboxSample"><span>체크박스3</span></label>
                </div>
                <div style="margin-top: -20px;">Selected: {{checkboxSample}}</div>
            </div>

            <div class="component-list-item">
                <h4 class="component-list-title">드롭다운</h4>
                <h5 class="component-list-sub-title">기본 드롭다운</h5>
                <div class="component-item-wrap">
                    <DefaultDropDown :list-item="dropDownTestArray" :selectedData.sync="dropDownData"></DefaultDropDown>
                </div>
                <span>선택된 값 :: {{dropDownData}}</span>

                <div class="component-item-wrap">
                    <DefaultDropDown :list-item="dropDownSecondTestArray" :selectedData.sync="dropDownSecondData"></DefaultDropDown>
                </div>
                <span>선택된 값 :: {{dropDownSecondData}}</span>
            </div>

            <div class="component-list-item">
                <h4 class="component-list-title">인풋박스</h4>
                <div class="component-item-wrap">
                    <input type="text" class="winex-input" placeholder="이름">
                </div>
            </div>

            <div class="component-list-item">
                <h4 class="component-list-title">테이블</h4>
                <h5 class="component-list-sub-title">테이블</h5>
                <div class="component-item-wrap">
                    <table class="winex-table">
                        <thead>
                            <tr>
                                <th>구분</th>
                                <th>일시</th>
                                <th>가격</th>
                                <th>보내는 사람</th>
                                <th>받는 사람</th>
                                <th>TXID</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>제시</td>
                                <td>2022.01.13</td>
                                <td>550USDC</td>
                                <td>보내는사람</td>
                                <td>받는사람</td>
                                <td>상세보기</td>
                            </tr>
                            <tr>
                                <td>제시</td>
                                <td>2022.01.13</td>
                                <td>550USDC</td>
                                <td>보내는사람</td>
                                <td>받는사람</td>
                                <td>상세보기</td>
                            </tr>
                            <tr>
                                <td>제시</td>
                                <td>2022.01.13</td>
                                <td>550USDC</td>
                                <td>보내는사람</td>
                                <td>받는사람</td>
                                <td>상세보기</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="component-list-item">
                <h4 class="component-list-title">아코디언</h4>
                <div class="component-item-wrap" style="display: flex; flex-direction: column;">
                    <Accordion v-for="item in accordionSample" :title="item.title" :content="item.content" :no="item.no" :category="item.category" v-bind:key="item.no"></Accordion>
                </div>
            </div>

            <div class="component-list-item">
                <h4 class="component-list-title">페이져</h4>
                <div class="component-item-wrap">
                    <WinexPagination :current-page="pagerSampleData.current_page" :total-page="pagerSampleData.total_page" :move-page="sampleMovePage"></WinexPagination>
                </div>
            </div>

            <div class="component-list-item">
                <h4 class="component-list-title">모달</h4>
                <h5 class="component-list-sub-title">기본 모달</h5>
                <div class="component-item-wrap">
                    <DefaultButton
                    :content="'ALERT'"
                    :onClick="clickDefaultAlertModalBtn"></DefaultButton>
                    <DefaultButton
                    :content="'CONFIRM'"
                    :onClick="clickDefaultConfirmModalBtn"></DefaultButton>
                </div>

                <h5 class="component-list-sub-title">커스텀 모달</h5>
                <div class="component-item-wrap">
                    <DefaultButton
                            :content="'CUSTOM'"
                            :onClick="customModalOpen"></DefaultButton>
                </div>
            </div>

            <div class="component-list-item">
                <h4 class="component-list-title">카드</h4>
                <h5 class="component-list-sub-title">와인 카드</h5>
                <div class="component-item-wrap">
                    <WineCardItem :wine-info="sampleWineInfo"></WineCardItem>
                </div>
            </div>

            <div class="component-list-item">
                <h4 class="component-list-title">와인 리스트 샘플</h4>
                <div class="component-item-wrap">
                    <router-link :to="{path:`/pcList`}" :target="'_blank'">
                        <DefaultButton
                        :content="'PC'"></DefaultButton>
                    </router-link>
                    <router-link :to="{path:`/mobileList`}" :target="'_blank'">
                        <DefaultButton
                                :content="'MOBILE'"></DefaultButton>
                    </router-link>
                </div>
            </div>
        </div>

        <!-- 모달 -->
        <!-- 믹스인으로 함수화 시켜야 되긴 하는데 그거까지 고려할 시간이 없어서 일단 이런 형태 -->
        <AlertModal
        :title="'제목'"
        :content="'내용내용내용<br/>내용내용내용'"
        :pcWidth="400"
        :button-content="'확인'"
        :button-class="'primary-btn'"
        :confirm-click-btn="clickDefaultAlertModalBtn"
        :is-open="defaultAlertModalOpen"
        :is-info="true">
        </AlertModal>
        <ConfirmModal
                :title="'컨펌 제목'"
                :content="'내용쓰<br/>내용내용내용'"
                :pc-width="640"
                :button-content="'확인'"
                :button-class="'primary-btn'"
                :confirm-click-btn="clickDefaultConfirmModalBtn"
                :cancel-button-content="'취소임'"
                :cancel-button-class="'outline-white-lld-btn'"
                :cancel-click-btn="clickDefaultConfirmCancelBtn"
                :is-open="defaultConfirmModalOpen"
                :is-info="true"
        >

        </ConfirmModal>
        <AlertModal
                :is-open="customModalOpenCheck"
                :use-custom="true"
                >
            <template v-slot:custom>
                <div>
                    커스텀
                </div>
                <div>
                    모달
                </div>
                <button @click="customModalOpenCheck = false">
                    닫기
                </button>
            </template>
        </AlertModal>
    </div>
</template>

<script>
    import DefaultButton from "../components/buttons/DefaultButton";
    import IconButton from "../components/buttons/IconButton";
    import DefaultDropDown from "../components/dropdowns/DefaultDropDown";
    import Accordion from "../components/Accordion";
    import WinexPagination from "../components/paginator/WinexPagination";
    import AlertModal from "../components/modals/AlertModal";
    import ConfirmModal from "../components/modals/ConfirmModal";
    import WineCardItem from "../components/common/WineCardItem";
    export default {
        name: "Components",
        components: {
            WineCardItem,
            ConfirmModal, AlertModal, WinexPagination, Accordion, DefaultDropDown, IconButton, DefaultButton},
        data() {
            return {
                radioSample:"",
                checkboxSample:[],
                dropDownTestArray:[
                    {
                        value:1,
                        text:"일번"
                    },
                    {
                        value:2,
                        text:"이번"
                    }
                ],
                dropDownData: null,
                dropDownSecondTestArray:[
                    {
                        value:"커피",
                        text:"커피"
                    },
                    {
                        value:"녹차",
                        text:"녹차"
                    },
                    {
                        value:"홍차",
                        text:"홍차"
                    },
                    {
                        value:"매실차",
                        text:"매실차"
                    },
                    {
                        value:"식혜",
                        text:"식혜"
                    },
                    {
                        value:"달고나라떼",
                        text:"달고나라떼"
                    },
                    {
                        value:"딸기라떼",
                        text:"딸기라떼"
                    },
                    {
                        value:"문성수",
                        text:"문성수"
                    }
                ],
                dropDownSecondData: null,
                accordionSample: [
                    {
                        no:1,
                        category:"서비스",
                        title:"와넥스는 무엇인가요?",
                        content:"와넥스 Winex는 Wine NFT 투자 플랫폼 Investment Platform입니다.<br/>" +
                            "투자 가치가 있는 훌륭한 와인 Fine wine을 프랑스 현지에 보관한 후 그에 해당하는 NFT를 발행하고 투자/거래를 하는 시스템입니다.<br/>" +
                            "즉, 실물인 와인과 보증서인 NFT를 활용하여 한국에서는 기존에 불가능했던 개인간의  거래를 가능케하며, 와인 거래시 생기는 비용을 최소화하는 신개념의 플랫폼입니다."
                    },
                    {
                        no:2,
                        category:"서비스",
                        title:"두번째 문의사항",
                        content:"두번째 문의사항 입니다."
                    },
                    {
                        no:3,
                        category:"서비스",
                        title:"세번째 문의사항",
                        content:"세번째 문의사항 입니다."
                    }
                ],
                pagerSampleData: {
                    total_page:20,
                    current_page:17
                },
                defaultAlertModalOpen:false,
                defaultConfirmModalOpen:false,
                customModalOpenCheck:false,
                sampleWineInfo: {
                    status:1,
                    brand:'Cote de Nuits',
                    name:'Domaine Comte Georges de Vogue Musigny Blanc Grand Cru 2019',
                    key:'0x08x08e09x456x154fsd',
                    price: {
                        coin:1270,
                        won:'5,260,000'
                    },
                    image: "https://cdn.imweb.me/thumbnail/20220508/45ceb1e85eda6.png"
                }
            }
        },
        methods: {
            openAlert() {
                alert("버튼 액션 오버라이드")
            },
            clickDefaultAlertModalBtn() {
                this.defaultAlertModalOpen = !this.defaultAlertModalOpen
                // 확인시 기능 Override
            },
            clickDefaultConfirmModalBtn() {
                this.defaultConfirmModalOpen = !this.defaultConfirmModalOpen
                // 확인시 기능 Override
            },
            clickDefaultConfirmCancelBtn() {
                this.defaultConfirmModalOpen = false
                // 취소시 기능 Override
            },
            customModalOpen() {
                this.customModalOpenCheck = true
                console.dir(this.customModalOpenCheck)
            },
            sampleMovePage(page) {
                // 통신 + 리스트 화면 갱신 처리 해주는 곳
                this.pagerSampleData.current_page = page
            }
        },
        created() {
            // 모바일 여부
            // console.log(this.$isMobile())
        }
    }
</script>

<style scoped lang="scss">
    #component-list-wrap {
        margin: 0 auto;
        padding: 20px;
        .component-list-item {
            .component-list-title {
                margin-bottom: 20px;
            }
            .component-list-sub-title {
                margin-bottom: 5px;
            }

            .component-item-wrap {
                width: 100%;
                display: flex;
                gap: 10px;
                margin-bottom: 20px;
            }
        }
    }
</style>
