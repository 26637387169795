<template>
    <div class="modal-wrap" v-if="isOpen">
        <div class="alert-modal-wrap" :style="{width:$isMobile()?'calc(100% - 20px)':pcWidth + 'px'}">
            <div class="modal-info-icon-wrap" v-if="isInfo">
                <img src="../../assets/images/icon/modal-info.png">
            </div>
            <div class="modal-info-icon-wrap flex justify-center" v-if="isIcon">
                <img :src="customIcon">
            </div>
            <div class="alert-modal-content">
                <h2>{{title}}</h2>
                <p v-html="content"></p>
            </div>
            <div class="confirm-modal-footer">
                <DefaultButton
                        :content="cancelButtonContent"
                        :class-list="selfCancelButtonClass"
                        :onClick="cancelClickBtn"
                ></DefaultButton>
                <DefaultButton
                        :content="buttonContent"
                        :class-list="selfButtonClass"
                        :onClick="confirmClickBtn"
                ></DefaultButton>
            </div>
        </div>
    </div>
</template>

<script>
    import DefaultButton from "../buttons/DefaultButton";
    export default {
        name: "ConfirmModal",
        components: {DefaultButton},
        props: {
            isInfo: {
                default:false,
                type:Boolean
            },
            title: {
                default:"타이틀",
                type:String
            },
            content: {
                default:"내용",
                type:String
            },
            buttonContent: {
                default:"확인",
                type:String
            },
            buttonClass: {
                default:"primary-btn",
                type:String
            },
            confirmClickBtn: {
                default:() => {},
                type:Function
            },
            cancelButtonContent: {
                default:"취소",
                type:String
            },
            cancelButtonClass: {
                default:"outline-white-lld-btn",
                type:String
            },
            cancelClickBtn: {
                default:() => {},
                type:Function
            },
            pcWidth: {
                default:640
            },
            isOpen: {
                default:false,
                type:Boolean
            },
            isIcon: {
                default:false,
                type:Boolean
            },
            customIcon: {

            }
        },
        data() {
            return {
                selfButtonClass: this.buttonClass,
                selfCancelButtonClass: this.cancelButtonClass
            }
        },
        mounted() {
            if(this.$isMobile()) {
                this.selfButtonClass = this.selfButtonClass + " h48"
                this.selfCancelButtonClass = this.selfCancelButtonClass + " h48"
            }
        }
    }
</script>

<style scoped>

</style>
